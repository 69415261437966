import React, { useState } from 'react';
import {
  Card,
  Input,
  Button,
  Typography,
  List,
  ListItem,
} from "@material-tailwind/react";
import GeneralAlert from '@/widgets/alerts/general';
import { updateTripDispatchFunc } from "@/apis/trips";
import sendSentryAlert from "@/utils/sentryUtil";

export function MaintainDispatchComponent({ dispatch }) {
  const [loading, setLoading] = useState(false);
  const [dispatchData, setDispatchData] = useState(dispatch);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDispatchData({ ...dispatchData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setAlert({ status: false, message: '', type: '' });

    const token = localStorage.getItem('token');
    try {
      const request_object = {
        starting_millage:dispatchData.starting_millage,
        ending_millage:dispatchData.ending_millage,
        dispatch_code:dispatchData.dispatch_code,
        id: dispatchData.id,
      };
      const updatedDispatch = await updateTripDispatchFunc(token, request_object);

      setLoading(false);
      setAlert({
        status: true,
        message: updatedDispatch
          ? 'Dispatch updated successfully.'
          : 'Failed to update Dispatch.',
        type: updatedDispatch ? 'success' : 'error',
      });
    } catch (error) {
      setLoading(false);
      sendSentryAlert(error);
      setAlert({
        status: true,
        message: error.response?.data?.message || 'System error. Contact Admin.',
        type: 'error',
      });
    }
  };

  return (
    <Card color="transparent" shadow={false} className="p-6">
      {alert.status && (
        <div className="mb-4">
          <GeneralAlert props={{ ...alert, setAlert }} />
        </div>
      )}
      <Typography variant="h5" color="blue-gray" className="mb-6">
        Update Dispatch Details
      </Typography>
      <form onSubmit={handleSubmit} className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {/* Dispatch Code */}
        <div>
          <Typography variant="body2" color="blue-gray">
            Dispatch Code
          </Typography>
          <Input
            size="md"
            name="dispatch_code"
            value={dispatchData.dispatch_code || ''}
            onChange={handleChange}
            placeholder="Enter Dispatch Code"
          />
        </div>

        {/* Route */}
        <div>
          <Typography variant="body2" color="blue-gray">
            Route
          </Typography>
          <Input
            size="md"
            name="route"
            value={dispatchData.route?.name || 'N/A'}
            disabled
          />
        </div>

        {/* Starting Mileage */}
        <div>
          <Typography variant="body2" color="blue-gray">
            Starting Mileage
          </Typography>
          <Input
            size="md"
            name="starting_millage"
            value={dispatchData.starting_millage || ''}
            onChange={handleChange}
            placeholder="Enter Starting Mileage"
          />
        </div>

        {/* Ending Mileage */}
        <div>
          <Typography variant="body2" color="blue-gray">
            Ending Mileage
          </Typography>
          <Input
            size="md"
            name="ending_millage"
            value={dispatchData.ending_millage || ''}
            onChange={handleChange}
            placeholder="Enter Ending Mileage"
          />
        </div>

        {/* Cost per KM */}
        <div>
          <Typography variant="body2" color="blue-gray">
            Cost per KM
          </Typography>
          <Input
            size="md"
            name="cost_per_km"
            type="number"
            step="0.01"
            value={dispatchData.cost_per_km || ''}
            onChange={handleChange}
            placeholder="Enter Cost per KM"
            disabled
          />
        </div>

        {/* Total Trip Cost */}
        <div>
          <Typography variant="body2" color="blue-gray">
            Total Trip Cost
          </Typography>
          <Input
            size="md"
            name="total_trip_cost"
            value={dispatchData.total_trip_cost || ''}
            disabled
          />
        </div>

        {/* Stages */}
        <div className="col-span-full">
          <Typography variant="body2" color="blue-gray">
            Stages
          </Typography>
          <List>
            {dispatchData.route?.stages?.length ? (
              dispatchData.route?.stages.map((stage, index) => (
                <ListItem key={index}>
                  <Typography variant="body1" color="blue-gray">
                    {`${index + 1}. ${stage.stageName}`}
                  </Typography>
                </ListItem>
              ))
            ) : (
              <Typography variant="body1" color="red">
                No stages available.
              </Typography>
            )}
          </List>
        </div>

        {/* Submit Button */}
        <div className="col-span-[1/3]">
          <Button type="submit" fullWidth size="md" disabled={loading}>
            {loading ? 'Updating...' : 'Update Dispatch'}
          </Button>
        </div>
      </form>
    </Card>
  );
}

export default MaintainDispatchComponent;
