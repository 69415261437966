import React, { useState } from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import AllDispatchComponent from "@/widgets/components/pagecomponents/dispatch/allDispatch";
import DipspatchedDispatchComponent from "@/widgets/components/pagecomponents/dispatch/dipspatchedDispatchComponent";
import ActiveDispatchComponent from "@/widgets/components/pagecomponents/dispatch/activeDispatchComponent";
import CompletedDispatchComponent from "@/widgets/components/pagecomponents/dispatch/completedDispatchComponent";
import CancelledDispatchComponent from "@/widgets/components/pagecomponents/dispatch/cancelledDispatchComponent";

function DispatchPage() {
  const [activeTab, setActiveTab] = useState("new");

  const data = [
    {
      label: "New",
      value: "new",
      component: <AllDispatchComponent />,
    },{
      label: "Dispatched",
      value: "dispatched",
      component: <DipspatchedDispatchComponent />
    },{
      label: "Active",
      value: "active",
      component: <ActiveDispatchComponent />
    },{
      label: "Completed",
      value: "completed",
      component: <CompletedDispatchComponent />
    },{
      label: "Cancelled",
      value: "canceled",
      component: <CancelledDispatchComponent />
    }
  ];

  return (
    <Tabs value={activeTab} className="w-full">
      <div className="w-full md:w-1/2 lg:w-1/3">
        <TabsHeader
          className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
          indicatorProps={{
            className:
              "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
          }}
        >
          {data.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => setActiveTab(value)}
              className={activeTab === value ? "text-gray-900" : ""}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
      </div>
      <TabsBody>
        {data.map(({ value, component }) => (
          <TabPanel key={value} value={value}>
            {component}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}

export default DispatchPage;
