import React, { useState, useEffect } from 'react';
import { DispatchTableComponent } from "@/widgets/components/reusableComponents/tables/dispatchTableComponent";
import GeneralAlert from '@/widgets/alerts/general';
import { fetchActiveDispatch } from "@/apis/trips"
import {
    Spinner,
    Button
  } from "@material-tailwind/react";

  import  ReusableListWithBadge  from "@/widgets/components/reusableComponents/listComponents/listWithBadge";

const ActiveDispatchComponent = () => {
    const headers = ["Dispatch Code","Default Vehicle","Default Vehicle Capacity","Assigned Vehicle","Assigned Vehicle Capacity","Route","Status", "Trip date","Session" ,"Booking Count", "Cost/KM", "Trip Cost"];
    const columns = ["dispatchCode","defaultRouteVehicleRegistration","defaultVehicleCapacity","AssignedVehicleRegistration","assignedVehicleCapacity","routeCode","trip_status", "trip_date","leg","bookingsCount", "cost_per_km", "total_cost"]
    const [alert, setAlert] = useState({ status: false, message: '', type: '' });
    const [loading, setLoading] = useState(true);
    const [dispatch, setDispatch] = useState([]);
    const [tripStatistics, setTripStatistics] = useState([])

    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const dispatchRecords = await fetchActiveDispatch(token,1,'active');
        if (dispatchRecords) {
          setDispatch(dispatchRecords.data);
          setTripStatistics(dispatchRecords.stats)
          setLoading(false);
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch Dispatch records at this time.",
            type: 'error',
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'We are having trouble fetching Dispatch data. Try again.',
          type: 'error',
        });
      }
    };
  
    useEffect(() => {
      setAlert({ status: false, message: '', type: '' });
      setLoading(true);   
      fetchData();
    }, []);

    if(loading){
        return <Spinner />
    }

    return (
        <>
          <div className='flex w-full p-5'>
                <ReusableListWithBadge
                  items={tripStatistics}
                />
          </div>
          { alert.status && <div className="w-[70%] mx-auto"><GeneralAlert props={{ ...alert, setAlert }} /></div> }      
          <DispatchTableComponent
            headers={headers}
            columns={columns}
            data={dispatch || []}
            sortableColumns={[0,1,2,3,4,5,6,7,8]}
            titleColumnIndex={0}
            searchEnabled={true}
            titleClickable={false}
            settingsColumn={true}
            hideCreateButton={true}
            fetchData={fetchData}
          />
        </>
    );
};


export default ActiveDispatchComponent;
